import { FC } from 'react';
import { openInNewTab } from './ProductPopup';
import { NicType } from './Products';

export type ProductSlideProps = {
  slide: NicType;
  setPopupSlide: React.Dispatch<React.SetStateAction<NicType | null>>;
};

export const renderCharacteristics = (rows: NicType['characteristics']): Array<JSX.Element> | null => {
  if (!rows || !rows.length) return null;
  return rows.map((row, index) => {
    return (
      <div className='characteristic' key={index}>
        <span className='characteristic__name'>{row.name}</span>:{' '}
        <span className='characteristic__value'>{row.value}</span>
      </div>
    );
  })
};

export const renderPower = (power: number): JSX.Element => {
  let circles: Array<JSX.Element> = [];

  for (let i = 0; i < power; i++) {
    circles.push(<div className='power-circle' key={i}></div>);
  }
  return <div className='products__power'>{circles}</div>;
};


export const ProductSlide: FC<ProductSlideProps> = ({
  slide,
  setPopupSlide
}): JSX.Element => {
  const handleBuyClick = (): void => {
    if (slide && slide.buyLink) openInNewTab(slide.buyLink);
  }

  const handleImageClick = (): void => {
    setPopupSlide(slide);
  };

  return (
    <div className='slide' key={slide.id}>
      <div className='slide__content'>
        <img className='slide__image' data-for='product-1' src={slide.imagePreview} alt='' onClick={handleImageClick}/>
        {/* <img className='slide__image' data-for='product-1' src={slide.imagePreview} alt='' /> */}
        <div className='slide__info'>
          <div className='products__title'>{slide.title}</div>
          <div className='products__description'>{slide.descriptionShort}</div>
          {renderPower(slide.power)}
          <div className='products__characteristic'>{renderCharacteristics(slide.characteristics)}</div>
          <a className='btn btn-black products__btn' href='#Buy' onClick={handleImageClick}>
            VIEW MORE
          </a>
        </div>
          <a
            className='btn btn-black products__btn products__btn-mob'
            data-for='product-1'
            style={{ cursor: 'pointer' }}
            onClick={handleBuyClick}
          >
            Buy now
          </a>
   
      </div>
    </div>
  );
};
