import Mockup from './../../img/Mockup.png';
import lips from './../../img/lips.png';

export const About = (): JSX.Element => {
  return (
    <section className='about'>
      <img className='img-left' src={Mockup} alt='mockup' />
      <div className='back-img-mob'></div>
      {/*<img className='img-right' src={lips} alt='lips' />*/}
      <div className='container'>
        <h2>About STRIPE</h2>
        <p className='about__text'>
          <span className='f-gilroy fw-600 fs-23'>STRIPE</span> is a
          <span className='f-gilroy fs-20 fw-500 lh-20'> FEDRS </span>
          nicotine pouches brand inspired by graffiti street art and a rebel spirit.<br/>
          It is a moveable masterpiece.
        </p>
        <p className='about__text'>
          <span className='f-gilroy fw-600 fs-23'>STRIPE</span> sticker-label characters are unique.<br/>
          Every detail is created by the real graffiti artist to be your everyday inspo.
        </p>
        <p className='about__text'>
          <span className='f-gilroy fw-600 fs-23'>STRIPE</span> — the best collab between joy and art.
        </p>
        <p className='about__text'>
          Art to the people!<br/>
          <span className='f-gilroy fw-600 fs-23'>STRIPE</span> to the people!
        </p>
      </div>
    </section>
  );
};
