import snowman from './../../img/snowman.png';
import banner10 from './../../img/banner-10mg.png';
import banner20 from './../../img/banner-20mg.png';
import Slider, { Settings } from 'react-slick';
import { FC, useCallback, useEffect, useState } from 'react';
import fakeData from './deleteMe.json';
import { ProductSlide } from './ProductSlide';

type CharacteristicType = {
  name: string;
  value: string;
}

export type NicType = {
  id: string;
  imagePreview: string;
  imageBackground: string;
  images: Array<string>;
  title: string;
  descriptionFull: Array<string>;
  descriptionShort: string;
  power: number;
  buyLink: string;
  characteristics: Array<CharacteristicType>;
};

type DataType = {
  lightNic: Array<NicType>;
  strongNic: Array<NicType>;
};

type ProductsProps = {
  setSlide: React.Dispatch<React.SetStateAction<NicType | null>>;
}

const NextArrow = (props: any): JSX.Element => {
  const { onClick } = props;
  return <div className={['arrow-next', 'products__arrow', 'arrow'].join(' ')} onClick={onClick}></div>;
}

const PrevArrow = (props: any): JSX.Element => {
  const { onClick } = props;
  return <div className="arrow-prev products__arrow arrow" onClick={onClick}></div>;
}

export const Products: FC<ProductsProps> = ({
  setSlide,
}): JSX.Element => {
  const [strongNic, setStrongNic] = useState<Array<NicType>>([]);
  const [lightNic, setLightNic] = useState<Array<NicType>>([]);

  const settings: Settings = {
    variableWidth: false,
    centerMode: true,
    dots: true,
    dotsClass: 'slick-dots products__dots',
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 3,
    infinite: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          variableWidth: false,
          centerMode: true,
          centerPadding: '100px',
          slidesToShow: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  const fetchData = useCallback(async () => {
    fetch('https://hellostripe.eu/api/data', { mode: 'no-cors' })
      .then((res) => res.json())
      .then((json: DataType) => {
        const { lightNic, strongNic } = json;
        setLightNic(lightNic);
        setStrongNic(strongNic);
      })
      .catch(() => {
        const { lightNic, strongNic } = fakeData as DataType;
        setLightNic(lightNic);
        setStrongNic(strongNic);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  const renderLight = (): JSX.Element => {
    return (
      <div className='products__container products__container-first'>
        <img className='back-img' src={snowman} alt='snowman' />
        <div className='container'>
          <div className='products__banner products__banner-first'>
            <img src={banner10} alt='10mg' />
          </div>
          <Slider
            {...settings}
            className='products__slider products__slider-first slick-initialized slick-slider slick-dotted'
          >
            {lightNic.map((slide) => (
              <ProductSlide slide={slide} key={slide.id} setPopupSlide={setSlide}/>
            ))}
          </Slider>
        </div>
      </div>
    );
  }

  const renderStrong = (): JSX.Element => {
    return (
      <div className='products__container products__container-second'>
        <div className='container'>
          <div className='products__banner products__banner-second'>
            <img src={banner20} alt='20mg' />
          </div>
          <Slider {...settings}
            className='products__slider products__slider-second slick-initialized slick-slider slick-dotted'
          >
            {strongNic.map((slide) => (
              <ProductSlide slide={slide} key={slide.id} setPopupSlide={setSlide} />
            ))}
          </Slider>
        </div>
      </div>
    );
  }

  return (
    <section className='products' id='products'>
      {renderLight()}
      {renderStrong()}
    </section>
  );
};
