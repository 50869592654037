import { FC } from 'react';
import image from './../img/Arturs-cut.png';

export type AgeWarningType = {
  showWarning: boolean;
  setShowWarning: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AgeWarning: FC<AgeWarningType> = ({
  setShowWarning,
  showWarning,
}): JSX.Element | null => {
  if (!showWarning) return null;
  return (
    <div id='years-popup' className='wrapper active'>
      <div className='popup popup-years'>
        <div className='popup__img'>
          <img src={image} alt='stripe' />
        </div>
        <div className='popup__title'>You must be over 18 to enter</div>
        <div className='popup__subtitle'>Are you 18+ years old?</div>
        <div className='popup__btns'>
          <button className='btn btn-grey' id='yes'>
            <div className='content' onClick={() => setShowWarning(false)}>Yes</div>
          </button>
          <button className='btn btn-grey' id='no'>
           <a href="https://google.com"> <div className='content'>No</div></a>
          </button>
        </div>
      </div>
    </div>
  );
};
