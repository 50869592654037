import Slider, {Settings} from 'react-slick';

import SugarPine0004 from './../../img/Sugar_pine0004.png';
import SnowFlakes0004 from './../../img/Snow_flakes0004.png';
import OurShop from './../../img/OurShop.png';
import Arturs2 from './../../img/Arturs2.png';

import slide11 from './../../img/slide-1-1.png';
import slide12 from './../../img/slide-1-2.png';
import slide13 from './../../img/slide-1-3.png';
import slide1top from './../../img/slide-1-top.png';

import {FC} from 'react';
import {AgeWarningType} from '../AgeWarning';

const NextArrow = (props: any): JSX.Element => {
    const {onClick} = props;
    return <div className={['arrow-next', 'first__arrow', 'arrow'].join(' ')} onClick={onClick}></div>;
}

const PrevArrow = (props: any): JSX.Element => {
    const {onClick} = props;
    return <div className={['arrow-prev', 'first__arrow', 'arrow'].join(' ')} onClick={onClick}></div>;
}

export const First: FC<AgeWarningType> = ({
                                              showWarning
                                          }): JSX.Element => {
    const settings: Settings = {
        variableWidth: false,
        // autoplay: true,
        dots: true,
        dotsClass: 'slick-dots first__dots',
        arrows: true,
        prevArrow: <PrevArrow/>,
        nextArrow: <NextArrow/>,
        slidesToShow: 1,
        infinite: true,
        responsive: [
            {
                breakpoint: 560,
                settings: {
                    variableWidth: false,
                    slidesToShow: 1,
                    arrows: false,
                    dots: true,
                },
            },
        ],
    };

    return (
        <section className='first' id='home'>
            <Slider {...settings} className='first__slider'>
                <div className='slide slide-who'>
                    <div className='container'>
                        <div className="slide-1__image-top">
                            <a href='https://fedrs.eu' target='_blank'><img src={slide1top} alt=""/></a>
                        </div>

                        <div className="slide-1__images">
                            <div className="slide-1__images-1">
                                <img src={slide11} alt=""/>
                            </div>
                            <div className="slide-1__images-2">
                                <img src={slide12} alt=""/>
                            </div>
                            <div className="slide-1__images-3">
                                <img src={slide13} alt=""/>
                            </div>
                        </div>
                        <div className="slide-1__title">
                            Add more bright STRIPES to your life
                        </div>
                        <a className='btn btn-red link-to-view' data-for='products' href='#view'>
                            View products
                        </a>
                    </div>
                </div>
                <div className='slide slide-shop'>
                    <div className='container'>
                        <img src={OurShop} alt="" className="slide-shop__img"/>
                        <a className='btn btn-red link-to-view' data-for='products' href='#view'>
                            View products
                        </a>
                    </div>
                </div>
            </Slider>
        </section>
    );
};
