import track from './../../img/track.png';
import Twist from './../../img/Twist.svg';
import Taste from './../../img/Taste.svg';
import Feel from './../../img/Feel.svg';
import Utile from './../../img/Utile.svg';
import Slider from 'react-slick';

const NextArrow = (props: any): JSX.Element => {
  const {onClick} = props;
  return <div className={['arrow-next', 'first__arrow', 'arrow'].join(' ')} onClick={onClick}></div>;
}

const PrevArrow = (props: any): JSX.Element => {
  const {onClick} = props;
  return <div className={['arrow-prev', 'first__arrow', 'arrow'].join(' ')} onClick={onClick}></div>;
}

export const How = (): JSX.Element => {
  const settings = {
    variableWidth: false,
    dots: true,
    dotsClass: 'slick-dots how__dots',
    arrows: true,
    prevArrow: <PrevArrow/>,
    nextArrow: <NextArrow/>,
    slidesToShow: 1,
    infinite: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 560,
        settings: {
          variableWidth: false,
          slidesToShow: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <section className='how'>
      <img src={track} className='back-img' alt='track'/>
      {/*<div className='container'>*/}
      <h2>How to use STRIPE?</h2>
      <Slider {...settings} className='how__slider'>
        <div className='slide'>
          <div className='slide__image'>
            <img src={Twist} alt='twist'/>
          </div>
          <div className='slide__content'>
            <div className='slide__title'>Twist it</div>
            <div className='slide__desc'>Open your can simply twist off the lid</div>
          </div>
        </div>
        <div className='slide'>
          <div className='slide__image'>
            <img src={Taste} alt='tuck'/>
          </div>
          <div className='slide__content'>
            <div className='slide__title'>Tuck it</div>
            <div className='slide__desc'>Put a nicotine pouch between your upper lip and gum</div>
          </div>
        </div>
        <div className='slide'>
          <div className='slide__image'>
            <img src={Feel} alt='feel'/>
          </div>
          <div className='slide__content'>
            <div className='slide__title'>Feel it</div>
            <div className='slide__desc'>Enjoy the STRIPE experience as you prefer (3-10 minutes)</div>
          </div>
        </div>
        <div className='slide'>
          <div className='slide__image'>
            <img src={Utile} alt='utile'/>
          </div>
          <div className='slide__content'>
            <div className='slide__title'>Dispose it</div>
            <div className='slide__desc'>Discard pouch in waste compartment</div>
          </div>
        </div>
      </Slider>
      <a className='how__btn btn btn-red' href='#add'>
        ADD MORE BRIGHT STRIPES
      </a>
      {/*</div>*/}
    </section>
  );
};
