import React, { useState } from 'react';
import { AgeWarning } from './components/AgeWarning';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { Main } from './components/Main';
import './sass/style.sass';

function App() {
  const [showWarning, setShowWarning] = useState<boolean>(true);

  return (
    <div className='App'>
      <AgeWarning showWarning={showWarning} setShowWarning={setShowWarning} />
      <Header />
      <Main showWarning={showWarning} setShowWarning={setShowWarning} />
      <Footer />
    </div>
  );
}

export default App;
