/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { renderCharacteristics, renderPower } from './ProductSlide'
import { NicType } from './Products';
import Slider, { Settings } from 'react-slick';

type ProductPopupType = {
  slide: NicType | null;
  setSlide: React.Dispatch<React.SetStateAction<NicType | null>>;
};

const NextArrow = (props: any): JSX.Element => {
  const { onClick } = props;
  return <div className={['arrow-next', 'popup-arrow', 'arrow'].join(' ')} onClick={onClick}></div>;
};

const PrevArrow = (props: any): JSX.Element => {
  const { onClick } = props;
  return <div className={['arrow-prev', 'popup-arrow', 'arrow'].join(' ')} onClick={onClick}></div>;
};

export function openInNewTab(href: string) {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href: href,
  }).click();
}

export const ProductPopup: FC<ProductPopupType> = ({ slide, setSlide }): JSX.Element | null => {
  const settings: Settings = {
    variableWidth: false,
    dots: false,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 1,
    infinite: true,
    autoplay: true,
  };


  if (!slide) return null;

  const handleBuyClick = (): void => {
    if (slide && slide.buyLink) openInNewTab(slide.buyLink);
  }

  const handleCloseClick = (): void => {
    setSlide(null);
  }

  const renderClose = (): JSX.Element => {
    return (
      <div className='popup-product__close' onClick={handleCloseClick}>
        <svg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <line
            y1='-0.5'
            x2='22.4355'
            y2='-0.5'
            transform='matrix(-0.728501 0.685045 -0.697355 -0.716726 16.5684 0.725586)'
            stroke='black'
          />
          <line
            y1='-0.5'
            x2='22.4255'
            y2='-0.5'
            transform='matrix(-0.710406 -0.703792 0.715803 -0.698302 17.5771 15.8096)'
            stroke='black'
          />
        </svg>
      </div>
    );
  };

  const renderCloseMob = (): JSX.Element => {
    return (
      <div className='popup-product__close-mob' onClick={handleCloseClick}>
        <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M16 7C16.5523 7 17 7.44772 17 8C17 8.55228 16.5523 9 16 9L16 7ZM0.292893 8.70711C-0.0976314 8.31658 -0.0976315 7.68342 0.292892 7.29289L6.65685 0.928933C7.04738 0.538409 7.68054 0.538409 8.07107 0.928933C8.46159 1.31946 8.46159 1.95262 8.07107 2.34315L2.41421 8L8.07107 13.6569C8.46159 14.0474 8.46159 14.6805 8.07107 15.0711C7.68054 15.4616 7.04738 15.4616 6.65685 15.0711L0.292893 8.70711ZM16 9L0.999999 9L0.999999 7L16 7L16 9Z'
            fill='black'
          />
        </svg>
      </div>
    );
  };

  const renderSlider = (): JSX.Element | null => {
    if (!slide.images || !slide.images.length) return null;
    return (
      <Slider {...settings} className='popup-product__slider'>
        {slide.images.map((image, index) => (
          <picture key={index}>
            <img src={image} alt='' />
          </picture>
        ))}
      </Slider>
    );
  };

  return (
    <div id='product-1' className='wrapper wrapper-to-close active'>
      <div className='popup popup-product'>
        {renderClose()}
        {renderCloseMob()}
        <div className='popup-product__base'>
          <div className='popup-product__title-mob'>{slide.title}</div>
          {renderSlider()}
          {renderPower(slide.power)}
          <div className='products__characteristic'>{renderCharacteristics(slide.characteristics)}</div>
        </div>

        <div className='popup-product__info' style={{ zIndex: 999 }}>
          <div className='popup-product__title'>{slide.title}</div>

          <div className='popup-product__text'>
            {slide.descriptionFull.map((text, index) => (
              <p key={index}>{text}</p>
            ))}
          </div>

          <a className='btn btn-black' onClick={handleBuyClick} style={{ cursor: 'pointer' }}>
            Buy now
          </a>
        </div>

        <div className='popup-product__footer'>
          18+ This product contains nicotine which is a highly addictive substance.
        </div>
        <div className='popup-product__after' style={{ backgroundImage: `url(${slide.imageBackground})` }}></div>
      </div>
    </div>
  );
};