/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import logoWhite from './../img/logo-white.svg'

export const Header = (): JSX.Element => {

  const handleNavClick = (e: React.MouseEvent): void => {
    const id = e.currentTarget.getAttribute('data-for');
    if (id) {
      const el = document.getElementById(id);
      if (el) el.scrollIntoView({ behavior: 'smooth' })
    }
  }



  return (
    <header className='header'>
      <div className='container'>
        <div className='header__logo'>
          <img src={logoWhite} alt='logo' />
        </div>
        <div className='header__menu-mobile'>
          <span>Menu</span>
          <div className='menu-burger'>
            <div className='line'></div>
            <div className='line'></div>
            <div className='line'></div>
          </div>
        </div>
        <div className='header__menu'>
          <div className='menu'>
            <div className='menu-item'>
              <a className='link-to-view' data-for='home' onClick={handleNavClick}>
                Home
              </a>
            </div>
            <div className='menu-item'>
              <a className='link-to-view' data-for='why' onClick={handleNavClick}>
                Why STRIPE?
              </a>
            </div>
            <div className='menu-item'>
              <a className='link-to-view' data-for='products' onClick={handleNavClick}>
                Our products
              </a>
            </div>
            <div className='menu-item'>
              <a className='link-to-view' data-for='contacts' onClick={handleNavClick}>
                Contacts
              </a>
            </div>
          </div>
          <a href='tel:+48221857682' className='header__phone'>
            <svg width='22' height='24' viewBox='0 0 22 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3.90625 0.693456C4.7142 -0.189112 6.10743 -0.289344 6.92752 0.74292L10.3103 5.00099C10.3103 5.00099 10.3103 5.00098 10.3103 5.00099C11.0494 5.93129 10.8991 7.27693 10.1603 8.0839C10.1603 8.08391 10.1603 8.0839 10.1603 8.0839L9.08837 9.25487C10.1983 11.2178 11.7029 12.8616 13.4995 14.0742L14.5741 12.9003C14.5741 12.9003 14.5741 12.9003 14.5741 12.9003C15.3103 12.096 16.5378 11.9289 17.3911 12.728L21.3115 16.3987C22.2645 17.2912 22.1763 18.8182 21.3658 19.7035C21.3658 19.7035 21.3658 19.7034 21.3658 19.7035L18.5575 22.7712C17.4562 23.9742 15.7734 24.3663 14.3696 23.6222C8.23094 20.3675 3.3249 15.0082 0.345603 8.30313C-0.33493 6.77157 0.0232338 4.93507 1.12438 3.73223L3.90625 0.693456ZM8.80073 9.56907L8.80126 9.5685C8.80108 9.56869 8.80091 9.56888 8.80073 9.56907ZM5.42049 2.18185C5.40038 2.18194 5.36133 2.19149 5.32135 2.23516L2.53949 5.27393C1.98478 5.87987 1.86898 6.72971 2.14526 7.35148C4.92895 13.6163 9.50918 18.6194 15.2438 21.6599C15.8115 21.9608 16.5879 21.8352 17.1424 21.2295L19.9507 18.1618C19.9908 18.1179 20 18.0745 20.0001 18.0513C20.0001 18.0491 20 18.0473 19.9999 18.0458L16.0942 14.3888C16.0918 14.3886 16.0883 14.3885 16.0834 14.3889C16.0616 14.3906 16.0251 14.4028 15.9893 14.4419L14.6255 15.9317C14.1861 16.4117 13.4689 16.5817 12.8654 16.2021M16.0886 14.3836C16.0886 14.3836 16.0885 14.3835 16.0886 14.3836V14.3836ZM5.42049 2.18185C5.42189 2.18184 5.42313 2.18188 5.42424 2.18195L5.42049 2.18185ZM5.42424 2.18195L8.79438 6.42408C8.79465 6.42681 8.7948 6.43108 8.79441 6.43724C8.79282 6.46221 8.7813 6.50278 8.74523 6.54219L7.38563 8.02737C7.38563 8.02737 7.38562 8.02737 7.38563 8.02737C6.94656 8.50698 6.79153 9.28851 7.13822 9.94618C8.51193 12.5521 10.4794 14.7013 12.8654 16.2021'
                fill='white'
              />
            </svg>
            <span>+48 22 185 76 82</span>
          </a>
        </div>
      </div>
    </header>
  );
}