import clock from './../../img/clock.svg';
import fruits from './../../img/fruits.svg';
import wallet from './../../img/wallet.svg';
import noSmoke from './../../img/no-smoke.svg';
import likesvg from './../../img/likesvg.svg';

export const Why = (): JSX.Element => {
  return (
    <section className='why' id='why'>
      <div className='container'>
        <h2>Why use STRIPE?</h2>
        <div className='why__items'>
          <div className='item'>
            <div className='item__icon'>
              <img src={clock} alt='clock' />
            </div>
            <div className='item__title'>Save your time</div>
            <div className='item__desc'>
              Don't break away from the things you really love. Enjoy STRIPE anytime, anywhere
            </div>
          </div>
          <div className='item'>
            <div className='item__icon'>
              <img src={fruits} alt='clock' />
            </div>
            <div className='item__title'>Variety of flavours and strengths</div>
            <div className='item__desc'>
              10 TOP-flavours and 2 levels of strength: 10 mg/p and 20 mg/p
            </div>
          </div>
          <div className='item'>
            <div className='item__icon'>
              <img src={wallet} alt='clock' />
            </div>
            <div className='item__title'>Save your money</div>
            <div className='item__desc'>
              The most effective nicotine replacement at a fair price
            </div>
          </div>
          <div className='item'>
            <div className='item__icon'>
              <img src={noSmoke} alt='clock' />
            </div>
            <div className='item__title'>Not bothering others</div>
            <div className='item__desc'>
              Be together with no limits
            </div>
          </div>
          <div className='item'>
            <div className='item__icon'>
              <img src={likesvg} alt='clock' />
            </div>
            <div className='item__title'>Less harm</div>
            <div className='item__desc'>
              Only pure nicotine, food flavourings and cellulose
            </div>
          </div>
        </div>
        <div className='why__phrase'>Add more bright stripes to your life</div>
        <h2>Choose your STRIPE</h2>
      </div>
    </section>
  );
};
