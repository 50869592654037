import videoFile from './../../video/ready site.mp4';

export const Video = (): JSX.Element => {
  return (
    <section className='video'>
      <div className='video-title'>
        DAMN!
        <br />
        check this out dude
      </div>
      <video controls  muted loop playsInline  itemType='video/mp4'>
        <source src={videoFile} />
      </video>
    </section>
  );
}