import { FC, useState } from 'react';
import { AgeWarningType } from './AgeWarning';
import { About } from './components/About';
import { First } from './components/First';
import { How } from './components/How';
import { Partner } from './components/Partner';
import { ProductPopup } from './components/ProductPopup';
import { NicType, Products } from './components/Products';
import { Video } from './components/Video';
import { Why } from './components/Why';

export const Main: FC<AgeWarningType> = ({
  setShowWarning,
  showWarning,
}): JSX.Element => {
  const [slide, setSlide] = useState<NicType | null>(null);
  return (
    <main>
      <First showWarning={showWarning} setShowWarning={setShowWarning} />
      <About />
      <Video />
      <Why />
      <Products setSlide={setSlide} />
      <How />
      <Partner />
      <ProductPopup slide={slide} setSlide={setSlide} />
    </main>
  );
};
